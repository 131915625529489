import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import moment from 'moment';
import api from '../../components/Api/Api';

const ITEMS_PER_PAGE = 10;

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  totalPacientes: {
    fontSize: '18px',
    fontWeight: '800'
  }
};

const useStyles = makeStyles(styles);

const PacientesList = () => {
  const classes = useStyles();

  let errorMsg = '';
  let [pacientes, setPacientes] = useState([]);
  let [totalPacientes, setTotalPacientes] = useState(0);
  let searchText = '';

  useEffect(() => {
    queryTotalPacientes();
    queryPacientes();
  }, [queryPacientes]);

  const queryTotalPacientes = () => {
    api('pacientesTotal')
      .then(data => {
        setTotalPacientes(data.total);
      })
      .catch(error => (errorMsg = error));
  };

  const queryPacientes = (total = ITEMS_PER_PAGE, searchText = '') => {
    const urlPacientes = `paciente?searchText=${searchText}`;
    console.log(urlPacientes);
    api(urlPacientes)
      .then(data => {
        let pacienteAsArray = data.pacientes.map(paciente => {
          const iconEdit = (
            <Tooltip id='tooltip-top' title='Editar Paciente' placement='top'>
              <Link to={'/admin/pacienteEdit/' + paciente._id}>
                <IconButton
                  aria-label='Edit'
                  className={classes.tableActionButton}
                >
                  <Edit
                    className={
                      classes.tableActionButtonIcon + ' ' + classes.edit
                    }
                  />
                </IconButton>
              </Link>
            </Tooltip>
          );

          const deleteEdit = (
            <Tooltip id='tooltip-top' title='Borrar Paciente' placement='top'>
              <IconButton
                aria-label='delete'
                className={classes.tableActionButton}
                onClick={() => deletePaciente(paciente._id)}
              >
                <Delete
                  className={classes.tableActionButtonIcon + ' ' + classes.edit}
                />
              </IconButton>
            </Tooltip>
          );

          return [
            paciente.code,
            paciente.estudioName,
            paciente.centroName,
            paciente.tratamientoName,
            paciente.fechaInicioTratamiento
              ? moment(paciente.fechaInicioTratamiento).format('YYYY-MM-DD')
              : '',
            paciente.fechaFinTratamiento
              ? moment(paciente.fechaFinTratamiento).format('YYYY-MM-DD')
              : '',
            iconEdit,
            deleteEdit
          ];
        });
        setPacientes(pacienteAsArray);
      })
      .catch(error => (errorMsg = error));
  };

  const deletePaciente = pacienteId => {
    api('paciente/' + pacienteId, 'DELETE')
      .then(data => {
        console.log(`pacienteId ${pacienteId} deleted!`);
        queryPacientes();
        queryTotalPacientes();
      })
      .catch(error => console.error(`Paciente not deleted: ${error}`));
  };

  const onChangeInputField = text => {
    console.log(text.target.value);
    searchText = text.target.value;
  };

  const handleSubmitCode = event => {
    queryPacientes(ITEMS_PER_PAGE, searchText);
    event.preventDefault();
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader color='info'>
            <h4 className={classes.cardTitleWhite}>
              Pacientes - (
              <span className={classes.totalPacientes}>{totalPacientes}</span>)
              <span style={{ float: 'right' }}>
                <Link
                  to={'/export'}
                  style={{ color: 'white', display: 'flex' }}
                >
                  <i className='material-icons'>add_circle</i>
                  <span style={{ marginLeft: '10px', marginRight: '20px' }}>Exportar</span>
                </Link>
                <Link
                  to={'/admin/pacienteNew'}
                  style={{ color: 'white', display: 'flex' }}
                >
                  <i className='material-icons'>add_circle</i>
                  <span style={{ marginLeft: '10px' }}>Nuevo Paciente</span>
                </Link>
              </span>
            </h4>
            <form onSubmit={event => handleSubmitCode(event)}>
              <p className={classes.cardCategoryWhite}>
                Código de paciente:{' '}
                <input
                  type='text'
                  placeholder='Introduce el codigo'
                  onChange={onChangeInputField}
                ></input>
              </p>
            </form>
          </CardHeader>
          {errorMsg !== '' ? (
            errorMsg
          ) : (
              <CardBody>
                {pacientes.length > 0 ? (
                  <Table
                    tableHeaderColor='info'
                    tableHead={[
                      'Codigo',
                      'Estudio',
                      'Centro',
                      'Tratamiento',
                      'Fecha Inicio',
                      'Fecha Fin',
                      'Acciones',
                      ''
                    ]}
                    tableData={pacientes}
                  />
                ) : (
                    'No hay pacientes dados de alta aún..'
                  )}
              </CardBody>
            )}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default PacientesList;
