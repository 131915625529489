import { getAuth } from './services/firebaseService';
import React, { useState, useEffect } from 'react';
import Admin from './layouts/Admin.js';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import SignIn from './components/SignIn/SignIn';
import Logout from './components/SignIn/Logout';
import PacienteExport from './adherencias-views/Pacientes/PacienteExport';

const hist = createBrowserHistory();

const App = () => {
  let [user, setUser] = useState(null);

  useEffect(() => {
    getAuth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  return user ? (
    <Router history={hist}>
      <Switch>
        <Route path='/admin' component={Admin} />
        <Route path='/export' component={PacienteExport} />
        <Route path='/logout' component={Logout} />
        <Redirect from='/' to='/admin/pacientes' />
      </Switch>
    </Router>
  ) : (
      <Router history={hist}>
        <SignIn />
      </Router>
    );
};

export default App;
