import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { getAuth } from '../../services/firebaseService';
import { createBrowserHistory } from 'history';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      //backgroundColor: theme.palette.common.white,
      backgroundColor: 'rgba(242,246,248,1)',
      background:
        'linear-gradient(to bottom, #eeeeef 0%, rgba(134,174,204,1) 100%);',
      height: 'calc(93vh)'
      //background: '@include background-image(linear-gradient(top, rgba(242,246,248,1) 0%, rgba(216,225,231,1) 50%, rgba(181,198,208,1) 51%, rgba(224,239,249,1) 100%))'
    },
    main: {
      backgroundColor: '#d2dee4'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const submitSignIn = event => {
  event.preventDefault();
  console.log('submiting');
  const email = document.getElementById('email').value;
  const password = document.getElementById('password').value;

  getAuth()
    .signInWithEmailAndPassword(email, password)
    .then(() => {
      console.log('Correctly signed in');
      createBrowserHistory().push('/admin/pacientes');
    })
    .catch(error => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error);
      if (errorCode === 'auth/wrong-password') {
        alert('Wrong password.');
      } else {
        alert(errorMessage);
      }
    });
};

export default function SignIn() {
  const classes = useStyles();
  console.log('Rendering SignIn');
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Entrar en Adherencias APP
        </Typography>
        <form className={classes.form} noValidate onSubmit={submitSignIn}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
            style={{ backgroundColor: 'rgb(232, 240, 254) !important' }}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Contraseña'
            type='password'
            id='password'
            autoComplete='current-password'
            style={{ backgroundColor: 'rgb(232, 240, 254) !important' }}
          />
          {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />*/}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}
