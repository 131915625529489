import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import ErrorLabel from '../../components/ErrorLabel/ErrorLabel';
import { withFormik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Calendar, CalendarControls } from 'react-yearly-calendar';
import createHistory from 'history/createBrowserHistory';
import moment from 'moment';
import 'assets/css/calendar.css';
import 'moment/locale/es';
import api from '../../components/Api/Api';
var _ = require('lodash');

const RANGO_TRATAMIENTO = '1';
const RANGO_INTRODUCIR_ADHERENCIA = '2';
const RANGO_BORRAR_ADHERENCIA = '3';

const SVR = 'SVR';
const RELAPSE = 'Relapse';
const REINFECTION = 'Reinfecció';
const UNKNOWN = 'Desconocido';

const styles = {
  form: {
    padding: '0 30px'
  },
  field: {
    height: '30px'
  },
  updateButton: {
    marginTop: '20px',
    marginLeft: '20px'
  }
};

const PacienteForm = props => {
  const { values, isSubmitting = false, isValid = true, errors } = props;

  console.log(values);

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  moment.locale('es');

  let [currentYear, setCurrentYear] = useState(parseInt(moment().format('YYYY')));
  let [fechaInicioTratamiento, setFechaInicioTratamiento] = useState(values.fechaInicioTratamiento);
  let [fechaFinTratamiento, setFechaFinTratamiento] = useState(values.fechaFinTratamiento);
  let [introducirAccion, setIntroducirAccion] = useState(0);
  let [totalAdherencias, setTotalAdherencias] = useState(0);

  const onDatePicked = date => {
    const tratamientoDays = getTratamientoDays();
    if (tratamientoDays > 0) {
      const endTratamiento = date.clone().add(tratamientoDays - 1, 'days');
      setFechaInicioTratamiento(date);
      setFechaFinTratamiento(endTratamiento);
      values.fechaInicioTratamiento = date;
      values.fechaFinTratamiento = endTratamiento;
      calculateAdherenciasRates();
    } else {
      alert('Escoge tratamiento para marcar las fechas');
    }
  };

  const dateComparator = (dateA, dateB) => {
    return dateA.isSame(dateB);
  };

  const onPickRange = (rangeStart, rangeEnd) => {
    if (introducirAccion === RANGO_INTRODUCIR_ADHERENCIA) {
      for (let date = rangeStart; date <= rangeEnd; date.add(1, 'days')) {
        values.adherencias = _.concat(values.adherencias, date.clone());
      }
    } else if (introducirAccion === RANGO_BORRAR_ADHERENCIA) {
      for (let dateToDelete = rangeStart; dateToDelete <= rangeEnd; dateToDelete.add(1, 'days')) {
        _.remove(values.adherencias, date => {
          return date.isSame(dateToDelete);
        });
      }
    } else {
      console.log('accion no definida')
    }

    values.adherencias = _.sortBy(
      _.uniqWith(values.adherencias, dateComparator)
    );
    calculateAdherenciasRates();
  };

  const calculateAdherenciasRates = () => {
    setTotalAdherencias(values.adherencias.length);
    values.totalAdherencias = values.adherencias.length;
    values.adherenciaPrevista = (values.adherencias.filter(adherencia => adherencia <= values.fechaFinTratamiento).length * 100 / getTratamientoDays()).toFixed(2);
    values.adherenciaTotal = (values.adherencias.length * 100 / getTratamientoDays()).toFixed(2);
  }

  const goToToday = () => {
    setCurrentYear(parseInt(moment().format('YYYY')));
  };
  const onPrevYear = () => {
    setCurrentYear(currentYear - 1);
  };
  const onNextYear = () => {
    setCurrentYear(currentYear + 1);
  };

  const introducirAccionOnChange = value => {
    setIntroducirAccion(value);
  };

  const getTratamientoDays = () => {
    const index = values.tratamientos.findIndex(
      tratamiento => tratamiento._id === values.tratamiento
    );
    return values.tratamientos[index] ? values.tratamientos[index].days : 0;
  };

  let customCSSclasses = {
    tratamientoPautado: {
      start: values.fechaInicioTratamiento.format('YYYY-MM-DD'),
      end: values.fechaFinTratamiento.format('YYYY-MM-DD')
    },
    adherencias: values.adherencias.map(adherencia =>
      adherencia.format('YYYY-MM-DD')
    )
  };

  return (
    <Form className={classes.form}>
      <ErrorLabel>{errors.generalError}</ErrorLabel>

      <GridContainer>
        <GridItem xs={12} sm={6} md={2}>
          <label htmlFor='Estudio' style={{ display: 'block' }}>
            Estudio:
          </label>
          <Field
            name='estudio'
            className={classes.field}
            value={values.estudio}
            component='select'
          >
            <option value={0} key={0}>
              Escoger estudio
            </option>
            {values.estudios.map(estudio => (
              <option value={estudio._id} key={estudio._id}>
                {estudio.name}
              </option>
            ))}
          </Field>
          <ErrorMessage name='estudio' component={ErrorLabel} />
        </GridItem>

        <GridItem xs={12} sm={6} md={2}>
          <label htmlFor='Centro' style={{ display: 'block' }}>
            Centro
          </label>
          <Field
            name='centro'
            className={classes.field}
            value={values.centro}
            component='select'
          >
            <option value={0} key={0}>
              Escoger centro..
            </option>
            {values.centros.map(centro => (
              <option value={centro._id} key={centro._id}>
                {centro.name}
              </option>
            ))}
          </Field>
          <ErrorMessage name='centro' component={ErrorLabel} />
        </GridItem>

        <GridItem xs={12} sm={6} md={2}>
          <label htmlFor='Código' style={{ display: 'block' }}>
            Código:
          </label>
          <Field name='code' className={classes.field} value={values.code} />
          <ErrorMessage name='code' component={ErrorLabel} />
        </GridItem>

        <GridItem xs={12} sm={6} md={2}>
          <label htmlFor='Tratamiento' style={{ display: 'block' }}>
            Tratamiento
          </label>
          <Field
            name='tratamiento'
            className={classes.field}
            value={values.tratamiento}
            component='select'
          >
            <option value={0} key={0}>
              Escoger tratamiento..
            </option>
            {values.tratamientos.map(tratamiento => (
              <option value={tratamiento._id} key={tratamiento._id}>
                {tratamiento.name}
              </option>
            ))}
          </Field>
          <ErrorMessage name='tratamiento' component={ErrorLabel} />
        </GridItem>

        <GridItem xs={12} sm={6} md={2}>
          <label htmlFor='FU12' style={{ display: 'block' }}>
            FU12
          </label>
          <Field
            name='fu12'
            component='select'
            value={values.fu12}
          >
            <option value={0} key={0}>Escoger opción..</option>
            <option value={SVR} key={SVR}>SVR</option>
            <option value={RELAPSE} key={RELAPSE}>Relapse</option>
            <option value={REINFECTION} key={REINFECTION}>Reinfecció</option>
            <option value={UNKNOWN} key={UNKNOWN}>Desconocido</option>
          </Field>
        </GridItem>

        <GridItem xs={12} sm={6} md={2}>
          <label htmlFor='Tomas' style={{ display: 'block' }}>
            Acción
          </label>
          <Field
            name='introducirRangos'
            component='select'
            value={introducirAccion}
            onChange={event => {
              introducirAccionOnChange(event.target.value);
            }}
          >
            <option value={0} key={0}>Escoger acción..</option>
            <option value={RANGO_TRATAMIENTO} key={RANGO_TRATAMIENTO}>Introducir fechas tratamiento</option>
            <option value={RANGO_INTRODUCIR_ADHERENCIA} key={RANGO_INTRODUCIR_ADHERENCIA}>Introducir adherencias</option>
            <option value={RANGO_BORRAR_ADHERENCIA} key={RANGO_BORRAR_ADHERENCIA}>Borrar adherencias</option>
          </Field>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
          <div>
            Total tomas:
            <strong>{values.totalAdherencias}</strong>
          </div>
          <div>
            Adherencia tiempo previsto:
            <strong>{values.adherenciaPrevista} %</strong>
          </div>
          <div>
            Adherencia total:
            <strong>{values.adherenciaTotal} %</strong>
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
          <CalendarControls
            year={currentYear}
            showTodayButton={true}
            onPrevYear={onPrevYear}
            onNextYear={onNextYear}
            goToToday={goToToday}
          />
          <Calendar
            year={currentYear}
            onPickDate={onDatePicked}
            onPickRange={onPickRange}
            customClasses={customCSSclasses}
            selectRange={introducirAccion !== RANGO_TRATAMIENTO}
            selectedRange={[fechaInicioTratamiento, fechaFinTratamiento]}
            disabled
          />
        </GridItem>
      </GridContainer>

      <Button
        color='info'
        className={classes.updateButton}
        type='submit'
        disabled={isSubmitting || !isValid}
      >
        Actualizar datos
      </Button>
      {isSubmitting ? <span>Enviando datos...</span> : ''}
    </Form>
  );
};

Yup.setLocale({
  mixed: {
    default: 'Valor no válido'
  },
  string: {
    min: 'Debes escoger una opción'
  }
});

const calculateAdherenciasGrid = (adherencias, fechaInicioTratamiento) => {
  let adherenciasGrid = _.fill(Array(150), 0);
  let daysOffset = 0;

  adherencias.forEach(adherencia => {
    daysOffset = Math.ceil(
      adherencia.diff(fechaInicioTratamiento, 'days', true)
    );
    if (daysOffset >= 0) {
      adherenciasGrid[daysOffset] = 1;
    }
  });
  return adherenciasGrid;
};

const transformValuesToPaciente = values => {
  return {
    id: values.id,
    code: values.code,
    fechaInicioTratamiento: values.fechaInicioTratamiento,
    fechaFinTratamiento: values.fechaFinTratamiento,
    centro: values.centro,
    estudio: values.estudio,
    tratamiento: values.tratamiento,
    adherencias: values.adherencias,
    fu12: values.fu12,
    totalAdherencias: values.totalAdherencias,
    adherenciaPrevista: values.adherenciaPrevista,
    adherenciaTotal: values.adherenciaTotal,
    centroName: values.centros.filter(centro => centro._id === values.centro)[0]
      .name,
    estudioName: values.estudios.filter(
      estudio => estudio._id === values.estudio
    )[0].name,
    tratamientoName: values.tratamientos.filter(
      tratamiento => tratamiento._id === values.tratamiento
    )[0].name,
    adherenciasGrid: calculateAdherenciasGrid(
      values.adherencias,
      values.fechaInicioTratamiento
    )
  };
};

export default withFormik({
  mapPropsToValues(props) {
    console.log(props);
    const values = {
      id: props.id ? props.id : '',
      code: props.code ? props.code : '',
      estudio: props.estudio ? props.estudio : '',
      centro: props.centro ? props.centro : '',
      tratamiento: props.tratamiento ? props.tratamiento : '',
      fechaInicioTratamiento: props.fechaInicioTratamiento
        ? moment(props.fechaInicioTratamiento)
        : moment(),
      fechaFinTratamiento: props.fechaFinTratamiento
        ? moment(props.fechaFinTratamiento)
        : moment(),
      adherencias: props.adherencias
        ? props.adherencias.map(adherencia => moment(adherencia))
        : [],
      tratamientos: props.tratamientos ? props.tratamientos : [],
      estudios: props.estudios ? props.estudios : [],
      centros: props.centros ? props.centros : [],
      fu12: props.fu12 ? props.fu12 : '',
      totalAdherencias: props.totalAdherencias ? props.totalAdherencias : 0,
      adherenciaPrevista: props.adherenciaPrevista ? props.adherenciaPrevista : 0,
      adherenciaTotal: props.adherenciaTotal ? props.adherenciaTotal : 0
    };
    return values;
  },

  handleSubmit: (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const transformedValues = transformValuesToPaciente(values);
    let method = 'POST';
    let url = 'paciente';

    if (values.id) {
      method = 'PUT';
      url = 'paciente/' + values.id;
    }

    api(url, method, transformedValues)
      .then(data => {
        console.log('paciente updated: ');
        console.log(data);
        setSubmitting(false);
        const history = createHistory();
        history.goBack();
      })
      .catch(error => {
        setErrors({ generalError: 'Error updating paciente: ' + error });
        setSubmitting(false);
      });
  },

  validationSchema: Yup.object().shape({
    estudio: Yup.string()
      .required('Debes seleccionar a qué estudio pertenece')
      .min(5),
    centro: Yup.string()
      .required('Debes seleccionar a qué centro pertenece')
      .min(5),
    tratamiento: Yup.string()
      .required('Debes seleccionar qué tratamiento se aplicará')
      .min(5),
    code: Yup.string().required('El código es obligatorio')
  }),

  isInitialValid: true,
  enableReinitialize: true
})(PacienteForm);
