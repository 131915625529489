import React from 'react';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import PacienteForm from './PacienteForm';
import api from '../../components/Api/Api';

class PacientePage extends React.Component {
  constructor(props) {
    super(props);
    this.pacienteID = props.match.params.id ? props.match.params.id : null;
    this.errorMsg = '';
  }

  state = {
    pending: true,
    formData: {
      id: '',
      code: '',
      estudio: '',
      centro: '',
      tratamiento: '',
      fechaInicioTratamiento: '',
      fechaFinTratamiento: '',
      adherencias: []
    },
    pacienteID: this.pacienteID,
    centros: [],
    estudios: [],
    tratamientos: []
  };

  componentDidMount() {
    this.queryCentros();
    this.queryEstudios();
    this.queryTratamientos();

    if (this.pacienteID) {
      this.queryPaciente();
    } else {
      this.setState({ pending: false });
    }
  }

  queryPaciente = () => {
    console.log(`fetching paciente with id ${this.pacienteID}`);
    api('paciente/' + this.pacienteID)
      .then(data => {
        console.log('paciente fetched');
        this.setState({ pending: false, formData: data.paciente });
      })
      .catch(error => {
        console.log(error);
        this.errorMsg = error;
      });
  };

  queryCentros = async () => {
    const data = await api('centro');
    this.setState({ centros: data.centros });
  };

  queryEstudios = async () => {
    const data = await api('estudio');
    this.setState({ estudios: data.estudios });
  };

  queryTratamientos = async () => {
    const data = await api('tratamiento');
    this.setState({ tratamientos: data.tratamientos });
  };

  render() {
    console.log('rendering form...');
    if (this.state.pending) {
      return <div>Waiting for data...</div>;
    } else {
      console.log(this.state);
    }

    return (
      <GridContainer style={{ width: '1344px' }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='info'>
              <h4 style={{ marginTop: '0', marginBottom: '0' }}>
                Editar Paciente
              </h4>
              <p style={{ marginTop: '0', marginBottom: '0' }}>
                Completar los datos del paciente
              </p>
            </CardHeader>
            <CardBody>
              {this.errorMsg}
              <GridContainer>
                <PacienteForm
                  id={this.pacienteID}
                  code={this.state.formData.code}
                  estudio={this.state.formData.estudio}
                  centro={this.state.formData.centro}
                  tratamiento={this.state.formData.tratamiento}
                  fechaInicioTratamiento={
                    this.state.formData.fechaInicioTratamiento
                  }
                  fechaFinTratamiento={this.state.formData.fechaFinTratamiento}
                  adherencias={this.state.formData.adherencias}
                  centros={this.state.centros}
                  estudios={this.state.estudios}
                  tratamientos={this.state.tratamientos}
                  fu12={this.state.formData.fu12}
                  totalAdherencias={this.state.formData.totalAdherencias}
                  adherenciaPrevista={this.state.formData.adherenciaPrevista}
                  adherenciaTotal={this.state.formData.adherenciaTotal}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default PacientePage;
