import React from 'react';

import api from '../../components/Api/Api';
import ReactExport from "react-export-excel";
var array = require('lodash/array');

class PacientePage extends React.Component {
  constructor(props) {
    super(props);
    this.pacienteID = props.match.params.id ? props.match.params.id : null;
    this.errorMsg = '';
  }

  state = {
    pending: true,
    pacientes: []
  };

  componentDidMount() {
    this.exportPacientes();
  }

  exportPacientes = () => {
    console.log(`exporting pacientes`);
    api('exportPaciente')
      .then(data => {
        //console.log('pacientes fetched', data.pacientes);
        console.log('pacientes fetched', this.processPacientes(data.pacientes));
        this.setState({ pending: false, pacientes: this.processPacientes(data.pacientes) });
      })
      .catch(error => {
        console.log(error);
        this.errorMsg = error;
      });
  };

  processPacientes(pacientes) {
    return pacientes.map(paciente => {
      const lastOne = array.findLastIndex(paciente.adherencias, function (o) { return o == 1; });
      const processedAdherencias = array.fill(paciente.adherencias, '-', lastOne + 1)
      return [paciente.code, paciente.centroName, paciente.estudioName, paciente.tratamientoName, paciente.fu12, ...processedAdherencias];
    })
  }

  render() {
    console.log('rendering form...', this.state.pacientes);
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const multiDataSet = [
      {
        columns: ['Code', 'Centro', 'Estudio', 'Tratamiento', 'Fu12', 'Dia 1', 'Dia 2', 'Dia 3', 'Dia 4', 'Dia 5', 'Dia 6', 'Dia 7', 'Dia 8', 'Dia 9', 'Dia 10', 'Dia 11', 'Dia 12', 'Dia 13', 'Dia 14', 'Dia 15', 'Dia 16', 'Dia 17', 'Dia 18', 'Dia 19', 'Dia 20', 'Dia 21', 'Dia 22', 'Dia 23', 'Dia 24', 'Dia 25', 'Dia 26', 'Dia 27', 'Dia 28', 'Dia 29', 'Dia 30', 'Dia 31', 'Dia 32', 'Dia 33', 'Dia 34', 'Dia 35', 'Dia 36', 'Dia 37', 'Dia 38', 'Dia 39', 'Dia 40', 'Dia 41', 'Dia 42', 'Dia 43', 'Dia 44', 'Dia 45', 'Dia 46', 'Dia 47', 'Dia 48', 'Dia 49', 'Dia 50', 'Dia 51', 'Dia 52', 'Dia 53', 'Dia 54', 'Dia 55', 'Dia 56', 'Dia 57', 'Dia 58', 'Dia 59', 'Dia 60', 'Dia 61', 'Dia 62', 'Dia 63', 'Dia 64', 'Dia 65', 'Dia 66', 'Dia 67', 'Dia 68', 'Dia 69', 'Dia 70', 'Dia 71', 'Dia 72', 'Dia 73', 'Dia 74', 'Dia 75', 'Dia 76', 'Dia 77', 'Dia 78', 'Dia 79', 'Dia 80', 'Dia 81', 'Dia 82', 'Dia 83', 'Dia 84', 'Dia 85', 'Dia 86', 'Dia 87', 'Dia 88', 'Dia 89', 'Dia 90', 'Dia 91', 'Dia 92', 'Dia 93', 'Dia 94', 'Dia 95', 'Dia 96', 'Dia 97', 'Dia 98', 'Dia 99', 'Dia 100', 'Dia 101', 'Dia 102', 'Dia 103', 'Dia 104', 'Dia 105', 'Dia 106', 'Dia 107', 'Dia 108', 'Dia 109', 'Dia 110', 'Dia 111', 'Dia 112', 'Dia 113', 'Dia 114', 'Dia 115', 'Dia 116', 'Dia 117', 'Dia 118', 'Dia 119', 'Dia 120', 'Dia 121', 'Dia 122', 'Dia 123', 'Dia 124', 'Dia 125', 'Dia 126', 'Dia 127', 'Dia 128', 'Dia 129', 'Dia 130', 'Dia 131', 'Dia 132', 'Dia 133', 'Dia 134', 'Dia 135', 'Dia 136', 'Dia 137', 'Dia 138', 'Dia 139', 'Dia 140', 'Dia 141', 'Dia 142', 'Dia 143', 'Dia 144', 'Dia 145', 'Dia 146', 'Dia 147', 'Dia 148', 'Dia 149', 'Dia 150'],
        data: this.state.pacientes
      }
    ];

    return (
      this.state.pacientes.length > 0
        ?
        <ExcelFile>
          <ExcelSheet dataSet={multiDataSet} name="Adherencias" />
        </ExcelFile>
        : null
    );
  }
}

export default PacientePage;
