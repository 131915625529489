import { getAuth } from '../../services/firebaseService';
import { createBrowserHistory } from 'history';

const onUserLogout = () => {
  getAuth()
    .signOut()
    .then(function() {
      console.log('user logged out');
      createBrowserHistory().push('/');
      window.location.reload();
    })
    .catch(function(error) {
      console.error('User could not be logged out');
      console.error(error);
    });
};

const Logout = () => {
  onUserLogout();
  return null;
};

export default Logout;
