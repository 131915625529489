const api = async (url, method = 'GET', values = null) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const apiUrl = `https://adherencias-api.herokuapp.com/api/${url}`;
  //const apiUrl = `http://localhost:8080/api/${url}`;

  let apiCallParameters = {
    method: method,
    headers: headers
  };

  if (values) {
    apiCallParameters = { ...apiCallParameters, body: JSON.stringify(values) };
  }

  console.log(
    `fetching url ${apiUrl} with method ${method} and apiCallParameters ${apiCallParameters}`
  );

  const response = await fetch(apiUrl, apiCallParameters);

  return await response.json();
};

export default api;
