import React from 'react';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import SnackbarContent from '../../components/Snackbar/SnackbarContent';
import CentroForm from './CentroForm';
import api from '../../components/Api/Api';

class CentroPage extends React.Component {
  state = {
    pending: true,
    formData: {
      code: '',
      name: '',
      address: '',
      tel: ''
    }
  };

  constructor(props) {
    super(props);
    this.centroID = props.match.params.id ? props.match.params.id : null;
    this.errorMsg = '';
  }

  componentDidMount() {
    if (this.centroID) {
      this.queryCentro();
    } else {
      this.setState({ pending: false });
    }
  }

  queryCentro = () => {
    console.log(`fetching centro with id ${this.centroID}`);
    api('centro/' + this.centroID)
      .then(data => {
        console.log('centro fetched');
        this.setState({ pending: false, formData: data.centro });
      })
      .catch(error => {
        console.log(error);
        this.errorMsg = (
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <SnackbarContent message={'ojoooo'} close color='info' />
            </GridItem>
          </GridContainer>
        );
      });
  };

  render() {
    let formContent = '';
    if (this.state.pending) {
      formContent = <div>Esperando datos...</div>;
    } else {
      formContent = (
        <CentroForm
          id={this.centroID}
          code={this.state.formData.code}
          name={this.state.formData.name}
          address={this.state.formData.address}
          tel={this.state.formData.tel}
        />
      );
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='info'>
                <h4 style={{ marginTop: '0', marginBottom: '0' }}>
                  Editar Centro
                </h4>
                <p style={{ marginTop: '0', marginBottom: '0' }}>
                  Completar los datos del centro
                </p>
              </CardHeader>
              <CardBody>
                {this.errorMsg}
                <GridContainer>{formContent}</GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default CentroPage;
