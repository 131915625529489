// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import BubbleChart from '@material-ui/icons/BubbleChart';
import ExitToApp from '@material-ui/icons/ExitToApp';

// core components/views for Admin layout
import DashboardPage from 'views/Dashboard/Dashboard.js';
import PacientesList from './adherencias-views/Pacientes/PacientesList.js';
import PacientePage from './adherencias-views/Pacientes/PacientePage.js';
import CentrosList from './adherencias-views/Centros/CentrosList.js';
import CentroPage from './adherencias-views/Centros/CentroPage.js';
import TratamientosList from './adherencias-views/Tratamientos/TratamientosList.js';
import TratamientoPage from './adherencias-views/Tratamientos/TratamientoPage.js';
import EstudiosList from './adherencias-views/Estudios/EstudiosList.js';
import EstudioPage from './adherencias-views/Estudios/EstudioPage.js';

const dashboardRoutes = [
  {
    path: '/inicio',
    name: 'Inicio',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin',
    sidebarVisible: true
  },
  {
    path: '/pacientes',
    name: 'Pacientes',
    icon: Person,
    component: PacientesList,
    layout: '/admin',
    sidebarVisible: true
  },
  {
    path: '/pacienteNew',
    name: 'Alta Paciente',
    icon: 'content_paste',
    component: PacientePage,
    layout: '/admin',
    sidebarVisible: false
  },
  {
    path: '/pacienteEdit/:id',
    name: 'Edición Paciente',
    icon: 'content_paste',
    component: PacientePage,
    layout: '/admin',
    sidebarVisible: false
  },
  {
    path: '/centros',
    name: 'Centros',
    icon: 'content_paste',
    component: CentrosList,
    layout: '/admin',
    sidebarVisible: true
  },
  {
    path: '/centroNew',
    name: 'Alta Centro',
    icon: 'content_paste',
    component: CentroPage,
    layout: '/admin',
    sidebarVisible: false
  },
  {
    path: '/centroEdit/:id',
    name: 'Edición Centro',
    icon: 'content_paste',
    component: CentroPage,
    layout: '/admin',
    sidebarVisible: false
  },
  {
    path: '/estudios',
    name: 'Estudios',
    icon: LibraryBooks,
    component: EstudiosList,
    layout: '/admin',
    sidebarVisible: true
  },
  {
    path: '/estudioNew',
    name: 'Alta Estudio',
    icon: 'content_paste',
    component: EstudioPage,
    layout: '/admin',
    sidebarVisible: false
  },
  {
    path: '/estudioEdit/:id',
    name: 'Edición Estudio',
    icon: 'content_paste',
    component: EstudioPage,
    layout: '/admin',
    sidebarVisible: false
  },
  {
    path: '/tratamientos',
    name: 'Tratamientos',
    icon: BubbleChart,
    component: TratamientosList,
    layout: '/admin',
    sidebarVisible: true
  },
  {
    path: '/tratamientoNew',
    name: 'Alta Tratamiento',
    icon: 'content_paste',
    component: TratamientoPage,
    layout: '/admin',
    sidebarVisible: false
  },
  {
    path: '/tratamientoEdit/:id',
    name: 'Edición Tratamiento',
    icon: 'content_paste',
    component: TratamientoPage,
    layout: '/admin',
    sidebarVisible: false
  },
  {
    path: '/logout',
    name: 'Salir',
    icon: ExitToApp,
    //component: Logout,
    layout: '',
    sidebarVisible: true
  }
];

export default dashboardRoutes;
