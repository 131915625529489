import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import ErrorLabel from '../../components/ErrorLabel/ErrorLabel';
import { withFormik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import api from '../../components/Api/Api';

const styles = {
  form: {
    padding: '30px'
  },
  fieldGroup: {
    marginTop: '20px'
  },
  fieldLabel: {
    marginTop: '20px'
  },
  field: {
    height: '30px'
  },
  updateButton: {
    marginTop: '20px',
    marginLeft: '20px'
  }
};

const CentroForm = props => {
  const { values, isSubmitting = false, isValid = true, errors } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  console.log(errors);
  return (
    <Form className={classes.form}>
      <ErrorLabel>{errors.generalError}</ErrorLabel>

      <GridItem xs={12} sm={12} md={8} style={{ marginTop: '20px' }}>
        <label htmlFor='Código' style={{ display: 'block' }}>
          Código:
        </label>
        <Field name='code' className={classes.field} value={values.code} />
        <ErrorMessage name='code' component={ErrorLabel} />
      </GridItem>

      <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
        <label htmlFor='Nombre' style={{ display: 'block' }}>
          Nombre:
        </label>
        <Field name='name' className={classes.field} value={values.name} />
        <ErrorMessage name='name' component={ErrorLabel} />
      </GridItem>

      <GridItem xs={12} sm={12} md={8} style={{ marginTop: '20px' }}>
        <label htmlFor='Dirección' style={{ display: 'block' }}>
          Dirección:
        </label>
        <Field
          name='address'
          type='text'
          className={classes.field}
          value={values.address}
          size='100'
        />
        <ErrorMessage name='address' component={ErrorLabel} />
      </GridItem>

      <GridItem xs={12} sm={12} md={8} style={{ marginTop: '20px' }}>
        <label htmlFor='Teléfono' style={{ display: 'block' }}>
          Teléfono:
        </label>
        <Field
          name='tel'
          type='tel'
          className={classes.field}
          value={values.tel}
        />
        <ErrorMessage name='tel' component={ErrorLabel} />
      </GridItem>

      <Button
        color='info'
        className={classes.updateButton}
        type='submit'
        disabled={isSubmitting || !isValid}
      >
        Actualizar datos
      </Button>
    </Form>
  );
};

export default withFormik({
  validationSchema: Yup.object().shape({
    code: Yup.string()
      .required('El código es obligatorio')
      .length(2, 'El código debe contener 2 caracteres'),
    name: Yup.string().required('Required'),
    address: Yup.string().required('La dirección es obligatoria'),
    tel: Yup.string().required('El teléfono es obligatorio')
  }),

  handleSubmit: (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    console.log(values);
    let method = 'POST';
    let url = 'centro';

    if (values.id) {
      method = 'PUT';
      url = 'centro/' + values.id;
    }

    api(url, method, values)
      .then(data => {
        console.log('centro updated: ' + data);
        setSubmitting(false);
      })
      .catch(error => {
        setErrors({ generalError: 'Error updating centro: ' + error });
        setSubmitting(false);
      });
  },
  isInitialValid: true
})(CentroForm);
