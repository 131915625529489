import * as firebase from 'firebase/app';
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCHAtgkS9Kt59Y7Ba7JK8OpNrLcJ5FMKqs",
    authDomain: "adherencias-app.firebaseapp.com",
    databaseURL: "https://adherencias-app.firebaseio.com",
    projectId: "adherencias-app",
    storageBucket: "adherencias-app.appspot.com",
    messagingSenderId: "246111065317",
    appId: "1:246111065317:web:ccb79cbcf44ff63f9b300c",
    measurementId: "G-P6JHSR8X27"
};

let firebaseInstance = null;

export const getDatabase = () => {
  console.log('getting Database')
  if (firebaseInstance === null ) {
    console.log('initializing firebase - database');
    firebaseInstance = firebase.initializeApp(firebaseConfig);
  }
  return firebaseInstance.database();
}

export const getAuth = () => {
  console.log('getting Auth')
  if (firebaseInstance === null ) {
    console.log('initializing firebase - auth');
    firebaseInstance = firebase.initializeApp(firebaseConfig);
  }
  return firebaseInstance.auth();
}

export const getGoogleProvider = () => {
  console.log('getting google provider');
  return new firebase.auth.GoogleAuthProvider();  
}