import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import api from '../../components/Api/Api';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  }
};

const useStyles = makeStyles(styles);

const TratamientosList = () => {
  const classes = useStyles();

  let errorMsg = '';
  let [tratamientos, setTratamientos] = useState([]);
  useEffect(() => {
    queryTratamientos();
  }, [queryTratamientos]);

  const queryTratamientos = () => {
    api('tratamiento')
      .then(data => {
        let tratamientoAsArray = data.tratamientos.map(tratamiento => {
          const iconEdit = (
            <Tooltip
              id='tooltip-top'
              title='Editar Tratamiento'
              placement='top'
            >
              <Link to={'/admin/tratamientoEdit/' + tratamiento._id}>
                <IconButton
                  aria-label='Edit'
                  className={classes.tableActionButton}
                >
                  <Edit
                    className={
                      classes.tableActionButtonIcon + ' ' + classes.edit
                    }
                  />
                </IconButton>
              </Link>
            </Tooltip>
          );
          return [
            tratamiento.code,
            tratamiento.name,
            tratamiento.days + ' días',
            iconEdit
          ];
        });
        setTratamientos(tratamientoAsArray);
      })
      .catch(error => (errorMsg = error));
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader color='info'>
            <h4 className={classes.cardTitleWhite}>
              Tratamientos
              <span style={{ float: 'right' }}>
                <Link
                  to={'/admin/tratamientoNew'}
                  style={{ color: 'white', display: 'flex' }}
                >
                  <i className='material-icons'>add_circle</i>
                  <span style={{ marginLeft: '10px' }}>Nuevo Tratamiento</span>
                </Link>
              </span>
            </h4>
            <p className={classes.cardCategoryWhite}>Lista de tratamientos</p>
          </CardHeader>
          <CardBody>
            {errorMsg !== '' ? (
              errorMsg
            ) : (
              <Table
                tableHeaderColor='info'
                tableHead={['Codigo', 'Nombre', 'Duración', '']}
                tableData={tratamientos}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default TratamientosList;
